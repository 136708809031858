import React, { Component } from 'react';
import { connect } from 'react-redux';
import { discountOperations } from '../../state/ducks/discount';
import { customerOperations } from '../../state/ducks/customer';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { H5, Btn } from "../../AbstractElements";
import { Row, Col, CardBody, CardFooter, CardHeader, Form, FormGroup, Label, Input, Container, Card } from 'reactstrap';
import { Save, Cancel } from '../../Constant';
import { Link } from 'react-router-dom';
import * as moment from 'moment';

class DiscountPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entries: [],
      customerData: [],
      formData: {
        user: '',
        discountCode: '',
        flatFee: '',
        validity: '',
      },
      editIndex: null,
    };
  }

  formatDate(yDate) {
      const formattedDate = moment(yDate).format("DD-MM-YYYY")
      return formattedDate;
  }
  async componentDidMount() {
    try {
      // Fetch all customers data

      const customerResponse = await this.props.listUser();
      const customerData = customerResponse.payload || [];

      // Fetch all discounts data
      const discountResponse = await this.props.listDiscount();
      const entries = discountResponse.payload || [];

      this.setState({ customerData, entries });
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };

  handleAddOrUpdate = async (e) => {
    e.preventDefault();
    const { formData } = this.state;
      // If no entry is being edited, add a new discount
      try {
        await this.props.addDiscount({
          user_id: formData.user_id,
          code: formData.discountCode,
          discount_value: formData.flatFee,
          valid_until: formData.validity,
        });
        const discountResponse = await this.props.listDiscount();
        const updatedEntries = discountResponse.payload || [];
        this.setState({ entries: updatedEntries });
        toast.success('Discount added successfully!');
      } catch (err) {
        console.error('Error adding discount:', err.response.data.message);
        toast.error(err.response ? err.response.data.message : 'Error adding discount.', { autoClose: 10000 });
      }
    // Reset form data and editIndex after submission
    this.setState({
      formData: {
        user: '',
        discountCode: '',
        flatFee: '',
        validity: '',
      },
      editIndex: null,
    });
  };

  onUpdate = async (e) => {
    e.preventDefault();
    try {
      const { entries } = this.state;
      const discountId = Number(e.target.getAttribute('data-test-id'));
      const discount = entries.find(dis => dis.id === discountId);
      let newStatus = discount.status === 1 ? 0 : 1;
      let updateResponse = await this.props.updateDiscountStatus({
        id: discountId,
        status: newStatus === 1 ? 1 : 0,
      });
      // Update the local state with the new status
      this.setState({
        entries: entries.map(dis =>
          dis.id === discountId ? { ...dis, status: newStatus } : dis // Update status of the clicked category
        ),
      });
      toast.success(updateResponse.message, { autoClose: 10000 });
      window.open("/discount-management/", "_self");
    } catch (err) {
      toast.error(err.response ? err.response.message : 'Update failed', { autoClose: 10000 });
      console.log(err);
    }
  };



  handleCancelEdit = () => {
    this.setState({
      formData: {
        user: '',
        code: '',
        flatFee: '',
        validity: '',
      },
      editIndex: null,
    });
  };

  render() {
    const { entries, formData, editIndex, customerData } = this.state;
    return (
      <div style={{ padding: '20px' }}>
        {/* Form */}
        <div className='edit-profile'>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className='d-flex align-items-center justify-content-between'>
                  <h5>{editIndex !== null ? 'Edit Entry' : 'Add New Entry'}</h5>

                </CardHeader>
                <Form onSubmit={this.handleAddOrUpdate}>
                  {/* <div style={{ marginBottom: '10px' }}> */}
                  <CardBody>
                    <Row>
                      <Col md="12">
                        {/* <FormGroup>
                          <Label className="form-label">
                            User
                          </Label>
                          <Input
                            type="select"
                            name="user_id"
                            value={formData.user_id}
                            onChange={this.handleChange}
                            style={{ width: '100%' }}
                            required
                          >
                            <option value="">Select User</option>
                            {customerData.map((customer) => (
                              <option key={customer.id} value={customer.id}>
                                {customer.name}
                              </option>
                            ))}
                          </Input>
                        </FormGroup> */}
                      </Col><FormGroup>
                        <Label className="form-label">
                          Discount Code
                        </Label>
                        <Input
                          type="text"
                          name="discountCode"
                          placeholder="Enter Discount Code"
                          value={formData.discountCode}
                          onChange={this.handleChange}
                          required
                          style={{ width: '100%' }}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label className="form-label">
                          Flat Fee
                        </Label>
                        <Input
                          type="number"
                          name="flatFee"
                          value={formData.flatFee}
                          onChange={this.handleChange}
                          required
                          style={{ width: '100%' }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="form-label">
                          Validity
                        </Label>
                        <Input
                          type="date"
                          name="validity"
                          value={formData.validity}
                          onChange={this.handleChange}
                          required
                          style={{ width: '100%' }}
                        />
                      </FormGroup>
                    </Row>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Btn attrBtn={{ color: "primary", type: "submit" }} > {editIndex !== null ? 'Update' : 'Add'}</Btn>&nbsp;&nbsp;&nbsp;
                    {editIndex !== null && (<Btn attrBtn={{ color: "primary", type: "reset" }} onClick={this.handleCancelEdit} >{Cancel}</Btn>)}
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
        <h5 style={{ color: "#890077" }}>Discount Management</h5>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>No</th>
              {/* <th>User</th> */}
              <th>Discount Code</th>
              <th>Flat Fee</th>
              <th>Validity</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {entries.length > 0 ? (
              entries.map((entry, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  {/* <td>{entry.display_name}</td> */}
                  <td>{entry.code}</td>
                  <td>{entry.discount_value}</td>
                  <td>{this.formatDate(entry.valid_until)}</td>
                  <td>
                    <button
                      className="btn btn-danger btn-sm ml-2"
                      data-test-id={entry.id}
                      onClick={this.onUpdate}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No Entries Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  addDiscount: discountOperations.addDiscount,
  updateDiscount: discountOperations.updateDiscount,
  updateDiscountStatus: discountOperations.updateDiscountStatus,
  deleteDiscount: discountOperations.deleteDiscount,
  listDiscount: discountOperations.listDiscount,
  listUser: customerOperations.listUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscountPage);
